import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Hi</h1>
        <p>
          The domains solarteamdelft.nl and solarteamdelft.com are for sale.
          </p><p>
          Interested? Send a mail to <a href="mailto:info@solarteamdelft.nl" className={"App-link"}>info@solarteamdelft.nl</a> or <a href="mailto:info@solarteamdelft.com" className={"App-link"}>info@solarteamdelft.com</a>
        </p>
      </header>
    </div>
  );
}

export default App;
